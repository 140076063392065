<template>
  <div class="page-theatre-detail">
    <details-modal-skeleton v-if="isLoading"/>
    <div v-else class="theatre-header" v-lazy:background-image="imageUrl(media.image1, true)">
      <div class="banner-layer">
        <h1 v-if="media != null">{{ media.title }}</h1>
        <div class="theather-header-content grid grid-cols-2">
          <div class="content-info col grid grid-cols-2">
            <div class="col detail-card">
              <div class="suggest-item">
                <!--                <span class="card__rate card__rate&#45;&#45;green">{{ media.rate ? media.rate : '0.0' }}</span>-->
                <img :src="Poster" v-lazy:background-image="imageUrl(media.image, true)" class="poster rounded-md"/>
              </div>

              <div v-if="user == null">
                <div class="detail-auth-btn">
                  <auth/>
                </div>
              </div>

              <div v-else-if="isLoadingAccess && user != null">
                <a href="javascript:void(0)" class="card__btn disabled">
                  <span>Түр хүлээнэ үү...</span>
                </a>
              </div>

              <div v-else-if="!isLoadingAccess && user != null">
                <router-link v-if="hasAccess && media.main_cat_id == 1" :to="`/watch/${media.id}/${media.epId}`"
                             class="card__btn">
                  <i class="isax isax-play"></i>
                  <span>Үзэх</span>
                </router-link>

                <router-link v-if="hasAccess && media.main_cat_id == 3" :to="`/theatre/watch/${media.id}`"
                             class="card__btn">
                  <i class="isax isax-play"></i>
                  <span>Үзэх</span>
                </router-link>

                <a v-if="!hasAccess && media.main_cat_id == 3" href="javascript:void(0)" @click="showQpayModal"
                   class="card__btn">
                  <i class="isax isax-video-add"></i>
                  <span>Түрээслэх - {{ parseInt(media.price) }}₮</span>
                </a>

                <router-link v-if="!hasAccess && media.main_cat_id == 1" to="/payment"
                             class="card__btn">
                  <i class="isax isax-video-add"></i>
                  <span>Эрх сунгах</span>
                </router-link>
              </div>
            </div>

            <div class="col">
              <!--              <div class="space-x-2">-->
              <!--                <span class="text-gray-400">Найруулагч:</span>-->
              <!--                <span class="text-white">{{ media.director ? media.director.name : 'N/A' }}</span>-->
              <!--              </div>-->
              <!--              <div class="space-x-2">-->
              <!--                <span class="text-gray-400">Гарсан жил:</span>-->
              <!--                <span class="text-white">{{ media.released_date ? media.released_date : 'N/A' }}</span>-->
              <!--              </div>-->

              <div class="space-x-2 mb-2">
                <span class="text-gray-400">Насны ангилал:</span>
                <router-link v-for="item in media.ages" :key="item.id" :to="`/age/${item.id}`">
                  <Tag class="t-tag w-tag" :value="item.title"></Tag>
                </router-link>
              </div>

              <div class="space-x-2">
                <span class="text-gray-400">Ангилал:</span>
                <router-link v-for="item in media.categories"
                             :key="item.id"
                             :to="'/series/' + item.id + '/all/all/all?t='+item.title">
                  <Tag class="t-tag" :value="item.title"></Tag>
                </router-link>
              </div>

              <!--              <div class="space-x-2">-->
              <!--                <span class="text-gray-400">Үргэлжлэх хугацаа</span>-->
              <!--                <span class="text-white">2ц 15м </span>-->
              <!--              </div>-->

              <p class="text-base txt-desc" v-html="media.description"></p>
            </div>
          </div>

          <div class="content-trailer col">
            <div class="trailer-frame">
              <iframe
                  v-if="media.trailer != null"
                  width="100%"
                  height="100%"
                  :src="`//www.youtube.com/embed/${getTrailerId(media.trailer)}`"
                  frameborder="0"
                  allowfullscreen
              />
              <div v-else class="no-trailer">
                <i class="isax isax-emoji-sad"></i>
                <span>Трэйлер олдсонгүй</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="page-theatre-detail-body">
      <div class="grid grid-cols-5 gap-10">
        <!-- Episode list-->
        <div class="col-span-3" v-if="media != null && media.main_cat_id == 1">
          <div class="episode-wrapper">
            <div class="episode-wrapper-body">
              <div class="episode-loader" v-if="media == null || isLoading">
<!--                <ProgressSpinner/>-->
              </div>
              <div v-else>
                <episode-list :disable-search="true" :media-id="media.id" :is-list="true"
                              :seasons="media.other_seasons"/>
              </div>
            </div>
          </div>
        </div>

        <div :class="`${(media != null && media.main_cat_id == 1) ? 'col-span-2' : 'col-span-5'}`">
          <div :class="`media-characters ${(media != null && media.main_cat_id == 1) ? '' : 'horz'}`">
            <!--Director and Studio-->
<!--            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4">-->
<!--              <div class="media-characters-item">-->
<!--                <h1 class="text-2xl mb-5">Шагналууд</h1>-->
<!--                <div v-if="media != null && media.awards != null" class="award-list">-->
<!--                  <div class="award-item" v-for="item in media.awards" :key="item.id">-->
<!--                    <div class="award-img">-->
<!--                      <img :src="`http://seeroo.mn${item.image}`" alt="award">-->
<!--                    </div>-->
<!--                    <span>{{ item.title }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="no-data" v-else>-->
<!--                  <img src="/img/not-found.png" alt="not-found">-->
<!--                  <h3>Шагналын мэдээлэл олдсонгүй</h3>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <Divider v-if="media != null && media.main_cat_id == 1"/>-->
            <!--Actors-->
<!--            <div class="award-wrap" v-if="media != null && media.actors != null">-->
<!--              <h1 class="text-2xl mb-5">Дүрүүд</h1>-->
<!--              <div class="grid grid-cols-1 gap-4">-->
<!--                <div v-if="media != null && media.characters != null" class="character-list">-->
<!--                  <router-link :to="`/character/anime/list/${item.id}`" class="award-item"-->
<!--                               v-for="item in media.characters" :key="item.id">-->
<!--                    <div class="award-img">-->
<!--                      <img :src="`http://seeroo.mn${item.image}`" alt="award">-->
<!--                    </div>-->
<!--                    <span>{{ item.name }}</span>-->
<!--                  </router-link>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>

      <div class="detail-divider"></div>

      <!--Related-->
      <div v-if="media != null && media.related != null">
        <h1 class="text-2xl mb-5">Төстэй цувралууд</h1>
        <div class="media-list">
          <div class="media-grid-wrapper">
            <div class="media-grid grid gap-4 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-cols-2">
              <router-link :to="`/theatre/detail/${item.id}`" v-for="item in media.related" :key="item.id"
                           class="media-item">
                <figure class="effect-sadie">
                  <img :src="Poster" v-lazy:background-image="imageUrl(item.image, true)" class="poster rounded-md"/>
                  <figcaption>
                    <div class="item-actions">
                      <a v-if="item.age_limit" href="javascript:void(0)">
                        <span>{{ item.age_limit }}+</span>
                      </a>
                    </div>
                    <p style="font-weight: bold;">{{ item.title }}</p>
                    <!--                    <p v-html="item.description"></p>-->
                  </figcaption>
                </figure>
              </router-link>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

  <Dialog v-model:visible="isVisibleQpay" :breakpoints="{'500px': '75vw', '500px': '90vw'}" :style="{width: '440px'}"
          :modal="true">
    <template #header>
      <p class="wallet-modal-header mini" v-if="step == 1">Кино түрээслэх</p>
      <p v-if="step==2"></p>
    </template>

    <div class="wallet-modal-body">
      <div v-if="step == 1">
        <h5>Та банкны апп дээрээс Qpay QR кодыг уншуулж төлбөрөө төлнө үү</h5>
        <div v-if="isProceeding" class="qpay-img-loader">
          <ProgressSpinner style="width:50px;height:50px" strokeWidth="3" animationDuration="1s"/>
        </div>
        <div class="qpay-img-wrap" v-else>
          <img class="qpay-img" v-if="qpayInvoice != null"
               :src="`data:image/png;base64,${qpayInvoice.qr_image}`" alt=""/>
        </div>
      </div>

      <div v-if="step == 2">
        <div class="qpay-success">
          <i class="isax isax-tick-circle"></i>
          <h5>{{ this.resultMsg }}</h5>
        </div>
      </div>
    </div>

    <template v-if="step == 1" #footer>
      <a v-if="isChecking" href="javascript:void(0)" class="primary-btn full">
        <span>Шалгаж байна...</span>
      </a>

      <a v-else href="javascript:void(0)" @click="checkPayment" class="primary-btn full">
        <span>Төлбөр шалгах</span>
      </a>
    </template>
  </Dialog>
</template>

<script>
import Poster from "../../assets/poster.png";
import {imageUrl} from "../../utils";
import {mapActions, mapGetters} from 'vuex';
import DetailsModalSkeleton from "../../skeletons/DetailsModalSkeleton.vue";
import api from "../../store/_boot/baseApi";
import EpisodeList from "../../components/EpisodeList";
import Dialog from "primevue/dialog";
// import ProgressSpinner from "primevue/progressspinner";
import Auth from "../../components/Auth.vue";

export default {
  components: {
    Auth,
    DetailsModalSkeleton,
    EpisodeList,
    Dialog,
    // ProgressSpinner
  },
  data() {
    return {
      Poster,
      isLoading: true,
      isLoadingAccess: true,
      hasAccess: false,
      isVisibleQpay: false,
      currentSlide: 1,
      step: 1,
      qpayInvoice: null,
      bill: null,
      isProceeding: false,
      isChecking: false,
      resultMsg: '',
      characters: []
    }
  },

  computed: {
    ...mapGetters(['user', 'media']),
  },

  created() {
    this.isLoading = true;
    this.getMediaDetail({vm: this, mediaID: this.$route.params.id})
    this.checkAccess();
  },

  watch: {
    '$route.params.id': {
      handler: function (val, oldVal) {
        if (oldVal !== val) {
          this.isLoading = true;
          this.getMediaDetail({vm: this, mediaID: val})
          this.checkAccess();
        }
      },
      deep: true,
      immediate: true
    }
  },

  mounted() {
    this.setBackLink(this.$route.fullPath);
  },

  methods: {
    ...mapActions(['getMediaDetail', 'setBackLink']),

    next() {
      this.$refs.carousel.next()
    },

    imageUrl,

    prev() {
      this.$refs.carousel.prev()
    },

    getTrailerId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[2].length === 11 ? match[2] : null;
    },

    showQpayModal() {
      this.isVisibleQpay = true
      this.createQpayInvoice();
    },

    createQpayInvoice() {
      if (this.isProceeding) {
        return false;
      }

      this.isProceeding = true;
      this.btnTxt = "Түр хүлээнэ үү";
      api.get(`/api/qpay/invoice/${this.user != null ? this.user.id : null}/${this.$route.params.id}/movie`).then(({data}) => {
        if (data.status) {
          this.qpayInvoice = data.data;
          setTimeout(() => {
            this.isProceeding = false;
            this.bill = data.bill;
          }, 1000);
        } else {
          setTimeout(() => {
            this.isProceeding = false;
            this.$Message.error(data.msg);
          }, 1000);
        }
      }).catch(e => {
        console.log(e);
        setTimeout(() => {
          this.isProceeding = false;
          this.$Message.error("Алдаа гарлаа");
          this.btnTxt = "Үргэлжлүүлэх";
        }, 1000);
      })
    },

    checkPayment() {
      this.isChecking = true;
      api.get(`/api/qpay/check/${this.bill}`).then(({data}) => {
        this.isChecking = false;

        if (data.status == 1) {
          this.$toast.success("Төлбөр амжилттай төлөгдлөө!");
          this.gotoFinal();
          this.resultMsg = data.msg;
          this.checkAccess();
          setTimeout(() => {
            this.isVisibleQpay = false;
          }, 3000);
        } else {
          this.$toast.error("Төлбөр төлөгдөөгүй байна!");
        }
      }).catch(e => {
        console.log(e);
        this.$notify.error({
          title: 'Алдаа гарлаа',
          message: 'Шалгахад алдаа гарлаа'
        });
      })
    },

    gotoFinal() {
      this.step = 2;
      this.paymentType = null;
    },

    checkAccess() {
      this.isLoadingAccess = true;
      api.get(`/api/m/tvod/check/${this.user != null ? this.user.id : null}/${this.$route.params.id}`).then(async ({data}) => {
        this.hasAccess = data.status;
        this.isLoadingAccess = false;
      }).catch(() => {
        this.isLoadingAccess = false;
      })
    },
  }
};
</script>